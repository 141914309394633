import browser from './browser';
export default {
    _os: undefined,
    _isIpad: false,
    _enum:{
        macOS: 'macOS',
        iOS: 'iOS',
        windows: 'windows',
        android: 'android',
        linux: 'linux'
    },
    _getOS() {
        let userAgent = browser.getUserAgent();
        let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
        let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        let iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const platform = window.navigator.userAgentData ? window.navigator.userAgentData.platform : window.navigator.platform;

        if (macosPlatforms.indexOf(platform) !== -1) {
            // best solution to ipad because after version 13 of iOS they changed the user agent, from iPad to Macintosh
            // E.G (before version 13): Mozilla/5.0 (iPad; CPU OS 10_3_3 like Mac OS X) 
            // E.G (after version 13): Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15)
            if (navigator.maxTouchPoint && navigator.maxTouchPoints > 2) {
                this._isIpad = true;
                return this._enum.iOS;
            }
            return this._enum.macOS;
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return this._enum.iOS;
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            return this._enum.windows;
        } else if (/Android/.test(userAgent)) {
            return this._enum.android;
        } else if (/Linux/.test(platform)) {
            return this._enum.linux;
        }
        return null;
    },
    system(){
        if(this._os === undefined){
            this._os = this._getOS();
        }
        return this._os;
    },
    is(operatingSystem){
        let system = this.system();
        return system && system == operatingSystem;
    },
    isIOS(){
        return this.is(this._enum.iOS);
    },
    isWindows(){
        return this.is(this._enum.windows);
    },
    isMacOS(){
        return this.is(this._enum.macOS);
    },
    isAndroid(){
        return this.is(this._enum.android);
    },
    isLinux(){
        return this.is(this._enum.linux);
    }
}
<template>
    <transition name="toast-drop">
        <section v-show="show" class="curtain-toast-container" :id="elemId" v-touch:tap="dismiss" v-touch:swipe="dismiss" v-touch:start="dismiss" v-touch:end="dismiss">
            <div v-if="show" class="toast-content-container" :class="{'shipping-info' : shippingInfoMode, 'no-action' : !actionData.text}">
                <p class="toast-message">
                    <i v-if="showIcon" class="toast-logo" :class="[!shippingInfoMode ? 'prz-bolt' : 'prz-local-shipping' ]"/>
                    <span v-html="message"/>
                </p>
                <div v-if="actionData && (actionData.text || shippingInfoMode)" class="toast-info-container" :class="{'info-link' : actionData.link && !shippingInfoMode}" id="toastInfoContainer" @click.self="actionData.link && goto(actionData.link)"> 
                    <span class="toast-info-text" :class="{'hasLink' : actionData.link && !shippingInfoMode}"> {{ actionData.text }} </span>
                    <span v-if="actionData.timer" class="toast-info-timer" id="toastInfoTimer"> 00:00:00 </span>
                    <i v-if="actionData.link" class="prz-chevron-right"/>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import Vue from 'vue';
import utilities from '@/Utilities/common';
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

export default {
    name: 'CurtainToast',
    components: {},
    props: {
        elemId: {
            type: String,
            default: 'curtainNotification',
        },
        message: {
            type: String,
            default: ''
        },
        actionData: { // { text: 'biscoitos', link: 'linkref', timer: '00:00:00'}
            type: Object, Array,
            default: null
        },
        delay: {
            type: Number,
            default: 10000
        },
        duration: {
            type: Number,
            default: 7000
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        shippingInfoMode: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: false,
            sessionTTL: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            let savedState = sessionStorage.getItem('curtainToastClicked');
            let ttl = sessionStorage.getItem('curtainToastTTL');
            if(!ttl) {
                sessionStorage.setItem('curtainToastTTL', this.addHours(new Date(), 1));
            } else {
                let now = new Date();
                let saved = new Date(Date.parse(ttl));
               /* console.log('now: ', now);
                console.log('saved: ', saved);*/
                this.sessionTTL = now < saved;
                if(!this.sessionTTL) {
                    sessionStorage.removeItem('curtainToastTTL');
                    sessionStorage.removeItem('curtainToastClicked');
                }
            }

           // console.log('has sessionTTL:', this.sessionTTL);
            
            if(!savedState && !this.sessionTTL) {
                this.message && setTimeout(() => {
                    this.show = true;
                    if(this.actionData && this.actionData.timer) {
                        setTimeout(() => {
                            this.initTimer(this.actionData.timer);
                        }, 500);
                    }

                    setTimeout(() => {
                        this.show = false;
                    }, this.duration);
                }, this.delay);
            }
            
            
        });
    },
    computed: {
    },
    methods: {
        goto(url = '') {
            sessionStorage.setItem('curtainToastClicked', true);
            utilities.goToUrl(url);
        },
        dismiss(direction) {
            if(direction == 'top') {
                utilities.disableBodyScroll();
                setTimeout(() => {
                    utilities.enableBodyScroll();
                }, 250);
            }
            sessionStorage.setItem('curtainToastClicked', true);
            this.show = false;
            this.$emit('dismiss');
        },
        initTimer(timer = '') {
            let toastInfoTimer = document.getElementById('toastInfoTimer');
            let toastInfoContainer = document.getElementById('toastInfoContainer');
            
            if(toastInfoTimer && timer) {
                // Set the date we're counting down to
                let countDownDate = new Date(parseInt(timer));
                // Update the countdown every 1 second
                let x = setInterval(function() {
                    // Get today's date and time
                    let now = new Date().getTime();
                    // Find the distance between now and the countdown date
                    let distance = countDownDate - now;
                    // Time calculations for days, hours, minutes and seconds
                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    // Display the result in the element with id="demo"
                    toastInfoTimer.innerHTML = hours.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" +
                        minutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ":" +
                        seconds.toLocaleString(undefined, {minimumIntegerDigits: 2});

                    // If the countdown is finished, write some text
                    if (distance < 0) {
                        clearInterval(x);
                        if (toastInfoContainer) {
                            toastInfoContainer.remove();
                        }
                    }
                }, 1000);
            }
        },
        addHours(date = new Date(), hours = 1) {
            date.setTime(date.getTime() + hours * 60 * 60 * 1000);
            return date;
        }
    },
};
</script>

<style lang="scss">
@import "./styles/curtain";
</style>

// more common mobile/desktop utilities instead of just scroll?

//let bodyNoScrollCssClass = null;

import request from '@/Utilities/httpRequest';
import operatingSystem from '@/Utilities/operating-system';
//import {storePdp} from '@/store/product-page';

export default {
    _scrollPosition: {},
    _header: document.getElementById('header'),
    _header_search_bar: document.getElementById('header_search_bar'),
    _header_search_bar_overlay: document.getElementById('header_overlay'),
    _nav_bar_mobile: document.getElementById('nav-mob-bottom'),
    _chkSticky: null,
    _chkStickyStyle: '',

    cookiebotCheckAll(){
        let cookiebot = typeof Cookiebot !== 'undefined' ? Cookiebot?.consent ?? null : null;
        if(!cookiebot){
            return false;
        }
        return (cookiebot.marketing ?? null) && (cookiebot.necessary ?? null) && (cookiebot.statistics ?? null) && (cookiebot.preferences ?? null);
    },

    disableBodyScroll(overlay = false, fixProdIndex = true, hideMobileNavBar = true, firefoxStickyHeader = false) {
        if( this._scrollPosition === null){
           // console.log("this._scrollPosition", this._scrollPosition)
            return;
        }
        //const htmlStyle = document.documentElement.style;
        const bodyStyle = document.body.style;
        /*if(bodyStyle && bodyStyle.position == 'fixed'){
            return;
        }*/

        this._scrollPosition = {
            x: window.pageXOffset || document.documentElement.scrollLeft ||
                document.body.scrollLeft,
            y: window.pageYOffset || document.documentElement.scrollTop ||
                document.body.scrollTop,
        };
        //console.log("this._scrollPosition in disable", this._scrollPosition)
        if(overlay && this._header_search_bar_overlay) {
            this._header_search_bar_overlay.style.display = 'block';
        }
        
//        if (fixProdIndex && this._header && this._header_search_bar && document.body.classList.contains('product-index')) {
//            this._header.style.paddingRight = '15px';
//            this._header_search_bar.style.paddingRight = '15px';
//        }

        if(operatingSystem.isWindows()) {
            this.setScrollFix();
        }    
        
        let windowScrollY = window.scrollY;
        //console.log("window.scrollY", window.scrollY )
        //console.log("this._header_search_bar",this._header_search_bar )
        //console.log(firefoxStickyHeader, "firefoxStickyHeader");
        if(!firefoxStickyHeader && window.scrollY > 40 && this._header_search_bar) {
           //console.log("JS FIREFOX")
            this._header_search_bar.classList.add('no-scroll-firefox-mode');
            windowScrollY += 56;
            if(this.getChkSticky()){
               // console.log("this._chkSticky", this._chkSticky)
                //this.getChkSticky().classList.add('chkSticky')
                const rect = this.getChkSticky().getBoundingClientRect();
                //console.log("rect", rect)
                this._chkStickyStyle = this.getChkSticky().style;
                this.getChkSticky().style = 'position: fixed; left: ' + rect.left + 'px; top: ' + rect.top + 'px; width: ' + rect.width.toFixed(2) + 'px!important;';
            }
        }

        bodyStyle.top = `-${windowScrollY}px`;
        bodyStyle.right = '0';
        bodyStyle.bottom = 'auto';
        bodyStyle.left = '0';
        bodyStyle.position = 'fixed';

    },

    getChkSticky(){
        if(!this._chkSticky){
            this._chkSticky = document.getElementById('chkSticky');
        }
        return this._chkSticky;
    },

    enableBodyScroll() {
        //console.log("AQUI enableBodyScroll ")
        const bodyStyle = document.body.style;
        //const scrollY = bodyStyle.top;
        bodyStyle.position = '';
        bodyStyle.top = '';
        bodyStyle.right = '';
        bodyStyle.bottom = '';
        bodyStyle.left = '';

        this.unsetScrollFix();
        if(this.getChkSticky()){
            //console.log("this._chkSticky", this._chkStickyStyle)
            //this.getChkSticky().classList.remove('chkSticky')
            this.getChkSticky().style = 'position: null; left: null; top: null; width: null;';

        }

        if (this._scrollPosition.y) {
            window.scrollTo(0, this._scrollPosition.y);
        }
        //console.log("this._scrollPosition depois", this._scrollPosition)
        if(this._header_search_bar_overlay) {
            this._header_search_bar_overlay.style.display = 'none';
        }

//        if (this._header && this._header_search_bar && document.body.classList.contains('product-index')) {
//            this._header.style.paddingRight = '0';
//           this._header_search_bar.style.paddingRight = '0';
//        }


        if(this._header_search_bar){
            this._header_search_bar.classList.remove('no-scroll-firefox-mode');
        }
    },

    scrollBottom() {
        window.scrollTo(0, document.body.scrollHeight);
    },

    isElementOutViewport(el, offsetTop = 0) { // horizontal && verical
        if(el) {
            let rect = el.getBoundingClientRect();
            return (
                (rect.bottom + offsetTop) < 0 ||
                rect.right < 0 ||
                rect.left > window.innerWidth ||
                rect.top > window.innerHeight
            );
        } 
        
        return false;
    },
    
    addUrlParam(param, value, encode = false) {
        const url = new URL(window.location.href);
        url.searchParams.set(param, !encode ? value : btoa(value));

        let state = {};
        state[param] = value;

        window.history.pushState(state, '', url);
    },

    getUrlParam(param) {
        const url = new URL(window.location.href);
        return url.searchParams.has(param) ? url.searchParams.get(param) : null;
    },

    // removeUrlParam(param = '', ) {
    //     const url = new URL(window.location.href);
    //     url.searchParams.delete(param);
    //     window.history.replaceState({}, '', url);
    // },

    isElementInViewport(container, element, partial = true) { // horizontal

        //Get container properties
        let cTop = container.scrollLeft;
        let cBottom = cTop + container.clientWidth;

        //Get element properties
        let eTop = element.offsetLeft;
        let eBottom = eTop + element.clientWidth;

        //Check if in view    
        let isTotal = (eTop >= cTop && eBottom <= cBottom);
        let isPartial = partial && (
            (eTop < cTop && eBottom > cTop) ||
            (eBottom > cBottom && eTop < cBottom)
        );

        //Return outcome
        return (isTotal || isPartial);
    },

    isScrollableElement(element) {
        return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
    },

    elementFullScrolled(element) {
        return element ? (element.offsetHeight + element.scrollTop) < element.scrollHeight : false;
    },

    elementFullScrolledHorizontal(element) {
        return element ? (element.scrollWidth - element.clientWidth - 16) <= parseInt(element.scrollLeft) : false;
    },
    
    async loadStyleSheet(path, id) {
        let head = document.getElementsByTagName('head')[0], // reference to document.head for appending/ removing link nodes
            link = document.createElement('link');           // create the link node
        link.setAttribute('href', path);
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('id', id);// how long to wait before failing
        head.appendChild(link);  // insert the link node into the DOM and start loading the style sheet

        return link; // return the link node;
    },

    async loadJsScript(path) {
        const plugin = document.createElement("script");
        plugin.setAttribute("src", path);
        plugin.setAttribute("type",'text/javascript');
        document.head.appendChild(plugin);
    },

    async simpleBackendRequest(url, params = {}) { // may be separated soon
        return await request.get(url, params, false);
    },

    eventModalApp(value){
        if(typeof App !== 'undefined'){
            App.js.callAppsJs('evtModal', value);
        }
    },
    execAppEvent(type, value){
        if(typeof App !== 'undefined'){
            App.js.callAppsJs(type, value);
        }
    },
    getScrollBarWidth(){
        const ww = window.innerWidth;
        const dw = document.body.offsetWidth;
        return (window.innerWidth - document.documentElement.clientWidth) > 0 ? window.innerWidth - document.documentElement.clientWidth : (ww - dw);
    },

    setScrollFix(){
        const scrollW = this.getScrollBarWidth();
        if(scrollW){
            document.body.style.backgroundColor = '#F7F8F9';
            document.body.style.paddingRight = scrollW + 'px';
//            document.body.style.width = 'calc(100vw - ' + scrollW + 'px)';
        }
    },

    unsetScrollFix(){
        document.body.style.backgroundColor = null;
        document.body.style.paddingRight = null;
        //document.body.style.width = null;
    },

    goToUrl(url = ''){
        if(url) {
            window.location.href = url;  
        }
    },
    goToUrlReplace(url = ''){
        if(url) {
            window.location.replace(url);
        }
    },

    isVueDev(){
        // check if is in Vue development mode
        return window.location.host.indexOf('.test') && window.location.port == '8080';
    },
    scrollToElement(element) {
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    },
    scrollIntoViewWithOffset(element, offset = 0, timeout = 0) {
        let top = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;
        setTimeout(()=> {
            window.scrollTo({
                behavior: 'smooth',
                top: top
            });
        }, timeout);
    },
    removeTags(str) {
        if ((str === null) || (str === '')) {
            return false;
        } else {
            str = str.toString();
        }
        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    },

    loadClappr() {
        let scriptUrl = 'https://cdn.jsdelivr.net/npm/clappr@latest/dist/clappr.min.js';
        const plugin = document.createElement("script");
        plugin.setAttribute("src", scriptUrl);
        plugin.setAttribute("type",'text/javascript');
        document.head.appendChild(plugin);
    },

    loadVideoJs() {
        this.loadStyleSheet('https://vjs.zencdn.net/8.16.1/video-js.css', 'videoJsCss');
        this.loadJsScript('https://vjs.zencdn.net/8.16.1/video.min.js');
    },

    legacyHandleRatings() {
        let pageBody = $('body');
        let changeRatingLabelAndInput = function($star) {
            let $container = $star.parents().filter('ul:first');
            let value = parseFloat($star.data('val'));
            let $globContainer = $star.parents().
                filter('.product_rating:first');
            if (value) {
                let valueSum = parseFloat($container.data('sum'));
                $globContainer.find(
                    '.rating-label[data-id=\'' + $container.data('id') +
                    '\'] .current-rate').html(value * valueSum);
                $globContainer.find(
                    '.rating-input[data-id=\'' + $container.data('id') + '\']').
                    val(value);
            }
        };

        pageBody.on('mouseenter',
            '.rating_5_starts.rate li.star1-2, .rating_5_starts.rate li.star2-2',
            function() {
                let $this = $(this);
                $this.addClass('selected');
                $this.prevAll().addClass('selected');
                $this.nextAll().removeClass('selected');
            });

        pageBody.on('mouseenter', '.rating_5_starts.rate', function() {
            $(this).addClass('pending');
        });
        pageBody.on('mouseleave', '.rating_5_starts.rate', function() {
            let $this = $(this);

            let $container = $this.parents().
                filter('.product_rating_container:first');
            if ($container.find('.rating-input').val() == 0) {
                $container.find('.pending').removeClass('pending');
                setTimeout(function() {
                    $container.find('.selected').removeClass('selected');
                }, 100);
            } else {
                $this.removeClass('pending');
                setTimeout(function() {
                    if (!$this.hasClass('pending')) {
                        var ds = $this.find('>li.default');
                        ds.addClass('selected');
                        ds.prevAll().addClass('selected');
                        ds.nextAll().removeClass('selected');
                    }
                }, 100);
            }
        });
        pageBody.on('click', '.rating_5_starts.rate li', function() {
            let $this = $(this);
            changeRatingLabelAndInput($this);
            $this.parents('.rating_5_starts.rate').
                find('li.star1-2, li.star2-2').
                removeClass('default');
            $this.addClass('default');
        });

    },
    // helper function to detect local ans session storage
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    // usage: if(utilities.storageAvailable("localStorage")) { ... }
    storageAvailable(type) {
	    let storage;
	    try {
	      storage = window[type];
	      const x = "__storage_test__";
	      storage.setItem(x, x);
	      storage.removeItem(x);
	      return true;
	    } catch (e) {
	      return (
	        e instanceof DOMException &&
	        // everything except Firefox
	        (e.code === 22 ||
	          // Firefox
	          e.code === 1014 ||
	          // test name field too, because code might not be present
	          // everything except Firefox
	          e.name === "QuotaExceededError" ||
	          // Firefox
	          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
	        // acknowledge QuotaExceededError only if there's something already stored
	        storage &&
	        storage.length !== 0
	      );
	    }
	},

    getMubOtiData(clearA2c){
        if(window.mub && window.mub.breadcrumbs){
            return window.mub.breadcrumbs.getMubOtiData(clearA2c);
        }

        return null;
    },
    fillParentFontSize(container, desiredWidth, minFontSize = 0) {
        var fontSize = parseInt(window.getComputedStyle(container, null).getPropertyValue('font-size'));
        while (container.scrollWidth > (desiredWidth + 2) && fontSize > minFontSize) {
            fontSize--;
            container.style.fontSize = fontSize + 'px';
            /*console.log('font size:', container.style.fontSize);
            console.log('scroll width:', container.scrollWidth);*/
        }
    },
    makeDraggable: function(element) {
        let currentPosX = 0, currentPosY = 0, previousPosX = 0, previousPosY = 0;
        element.onmousedown = dragMouseDown;
        function dragMouseDown(e) {
            e.preventDefault();
            // Get the mouse cursor position and set the initial previous positions to begin
            previousPosX = e.clientX;
            previousPosY = e.clientY;
            // When the mouse is let go, call the closing event
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e.preventDefault();
            // Calculate the new cursor position by using the previous x and y positions of the mouse
            currentPosX = previousPosX - e.clientX;
            currentPosY = previousPosY - e.clientY;
            // Replace the previous positions with the new x and y positions of the mouse
            previousPosX = e.clientX;
            previousPosY = e.clientY;
            // Set the element's new position
            element.style.top = (element.offsetTop - currentPosY) + 'px';
            element.style.left = (element.offsetLeft - currentPosX) + 'px';
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
};

export default {
    isBrowser: function (){
        return typeof window !== 'undefined' && typeof document !== 'undefined';
    },
    getUserAgent: function(){
        return window.navigator.userAgent;
    },
    isIOS: function() {
        let userAgent = this.getUserAgent();
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
            return true;
        }

        return false;
    },
    checkIosVersion: function () {
        let userAgent = this.getUserAgent();
        return /(iPhone|iPad) OS ([1-9]*)/g.exec(userAgent)?.[2] || 0;
    },
    isIOSlegacy: function() {
        if(!this.isIOS()) {
            return false;
        }
        
        return this.checkIosVersion() < 16;
    },
    isSafari: function () {
        if(!this.isBrowser()){
            return false;
        }
        var uat = this.getUserAgent();
        var isSafari = /Safari/i.test(uat) && !/Chrome/i.test(uat) && !/Edge/i.test(uat);
        var isAppiOS = /AppProzisStore/i.test(uat) && /iOS/i.test(uat);
        return isSafari || isAppiOS;
    },
    isApp: function(){
        if(!this.isBrowser()){
            return false;
        }
        var uat = this.getUserAgent();
        return /AppProzisStore/i.test(uat);
    },
    isInstagramApp: function(){
        if(!this.isBrowser()){
            return false;
        }
        var uat = this.getUserAgent();
        return /Instagram/i.test(uat);
    },
    isChrome: function () {
        if(!this.isBrowser()){
            return false;
        }
        var ua = this.getUserAgent();
        return /Chrome/i.test(ua);
    },
    isFirefox: function () {
        if(!this.isBrowser()){
            return false;
        }
        var ua = this.getUserAgent();
        return /Firefox/i.test(ua);
    },
    isIE11: function () {
        if(!this.isBrowser()){
            return false;
        }
        return !!(window.MSInputMethodContext && document.documentMode);
    },
    isIE10: function () {
        if(!this.isBrowser()){
            return false;
        }
        var ua = this.getUserAgent();
        return /MSIE 10/.test(ua);
    },
    isIE9: function () {
        if(!this.isBrowser()){
            return false;
        }
        var ua = this.getUserAgent();
        return /MSIE 9/.test(ua);
    },
    isIE: function(version){
        if (version === 11) {
            return this.isIE11();
          }
          if (version === 10) {
            return this.isIE10();
          }
          if (version === 9) {
            return this.isIE9();
          }
          return this.isIE11() || this.isIE10() || this.isIE9();
    },
    getBrowser: function() {
        let userAgent = navigator.userAgent;
        let browser = "Unknown";

        // Detect Chrome
        if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
            browser = "chrome";
        }
        // Detect Chromium-based Edge
        else if (/Edg/.test(userAgent)) {
            browser = "edge";
        }
        // Detect Firefox
        else if (/Firefox/.test(userAgent)) {
            browser = "firefox";
        }
        // Detect Safari
        else if (/Safari/.test(userAgent)) {
            browser = "safari";
        }
        // Detect Internet Explorer
        else if (/Trident/.test(userAgent)) {
            browser = "ie";
        }

        return browser;
    }
}
(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define([, "axios"], factory);
	else if(typeof exports === 'object')
		exports["prz-curtaintoast"] = factory(require("vue"), require("axios"));
	else
		root["prz-curtaintoast"] = factory(root["Vue"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 
const axios = require('axios');
const CancelToken = axios.CancelToken;

export default{
    rid: null,  // request ID
    refRoute: null, // referrer application route
    cache: {},
    cancelFunctions: {},
    errorUrl: null,
    get: async function(url, params = null, useCache = true){
        return this._call('get', url, params, useCache);
    },
    post: async function(url, params = null, useCache = true, headers = {}){
        return this._call('post', url, params, useCache, headers);
    },
    cancelLastCall: function(url){
        const key = self.btoa(url);
        if(this.cancelFunctions.hasOwnProperty(key)){
            this.cancelFunctions[key]();
        }
    },
    _call: async function(type, url, params = null, useCache = true, headers = {}){
        if(!url || url === 'null'){
            return this._formatError('ERRORPARAMS', 'Error: no url!',{});
        }
        let config = {
            method: type,
            withCredentials: true,
            headers: this._getHeaders(headers),
            validateStatus: false,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            url: url
        };
        if(params){
            if(type === 'post'){
                config.data = params;
            }else{
                config.params = params;
            }
        }

        //Cancel function
        try{
            const key = self.btoa(url);
            config.cancelToken = new CancelToken((c) => { this.cancelFunctions[key] = c; });
        }catch(e){}

        //Cache
        const keyCache = useCache ? self.btoa(JSON.stringify(config)) : null;
        if(useCache){
            if(this.cache.hasOwnProperty(keyCache)){
                return this.cache[keyCache];
            }
        }
        try{
            const rst =  await axios(config);
            if(rst.status == 500){
                return this._formatError('ERRORONSERVER', 'Error on server side!',rst);
            }
            if(rst.status == 404){
                //console.log('axios 404 ', typeof Bugsnag);
                if(typeof Bugsnag !== 'undefined'){
                    Bugsnag.notify('axios404', 'Not found', {url: url}, 'error');
                }
                return this._formatError('NETWORK', 'Error comunicating with server!',rst);
            }
            if(useCache){
                this.cache[keyCache] = rst;
            }
            if(rst.hasOwnProperty('data') && rst.data){
                this._infolog(rst.data);
            }
            return rst;
        }catch(e){
            // this occurs because of network issues or failed CORS check
            if (!(e instanceof Error)) {
                e = new Error(e);
            }
            //this._logError(config, e);
            return this._formatError('NETWORK', 'Error comunicating with server!', e);
        }
    },
    _infolog: function(data = {}){
        if(data.hasOwnProperty('dalelogs') && (typeof dale_logs !== "undefined")){
            window.dale_logs = dale_logs.concat(data.dalelogs);
        }
        if(data.hasOwnProperty('infolog') && (typeof addDynamicInfo !== "undefined")){
            const infolog = data.infolog??{};
            for (const [key, value] of Object.entries(infolog)) {
                addDynamicInfo(key,value);
            }
        }
    },
    _getHeaders: function(headers = {}){
        headers['X-Requested-With'] = 'XMLHttpRequest';
        headers['X-Referrer'] = window.location.href;
        if(this.rid === null){
            this.rid = '';
            if(window.mub){
                this.rid = window.mub.rid;
                this.refRoute = window.mub.page;
            }
            else if(document){
                let body = document.getElementsByTagName('body')[0];
                const rid = body.getAttribute('data-rid');
                if(rid && rid.length){
                    this.rid = rid;
                }
            }
        }
        if(this.rid && this.rid.length){
            headers['X-Osit-Rid'] = this.rid;
        }
        if(this.refRoute && this.refRoute.length){
            headers['X-Prz-Route'] = this.refRoute;
        }

        return headers;
    },
    _formatError: function(code, message, details = {}){
        return {
            data:{
                hasError: true,
                error: code,
                message: message,
                details: details
            }
        };
    },
    // _logError: function(config, err){
    //     const ignore = [
    //         'Request aborted', 'Cancel',
    //     ];

    //     if(ignore.includes(err.message)){
    //         return;
    //     }

    //     if(!this.errorUrl){
    //         const curUrl = new URL(window.location.href);
    //         const parts = curUrl.pathname.split('/');
    //         if(parts && parts.length > 2){
    //           this.errorUrl = '/' + parts[1] + '/' + parts[2] + '/javascript/networkError';
    //         }
    //         else if(window.location.port === '8080'){
    //           // vue development mode
    //           this.errorUrl = "https://"+window.location.hostname+"/pt/pt/javascript/networkError";
    //         }
    //     }

    //     const data = {
    //         error: err.name + ': '+err.message,
    //         errorCode: err.code ? err.code : null,
    //         rid: this.rid,
    //         method: config.method,
    //         destination: config.url,
    //         origin: window.location.href,
    //         params: config.params ? config.params : null,
    //     };

    //     try{
    //         if(navigator && typeof navigator.sendBeacon === 'function'){
    //             // send using "sendBeacon" because it's non blocking
    //             //console.log('sendBeacon', this.url, data);
    //             navigator.sendBeacon(this.errorUrl, JSON.stringify(data));
    //         }
    //         else{
    //             //console.log('TODO: sendBeacon not supported');
    //             var xhr = new XMLHttpRequest();
    //             xhr.open("POST", this.errorUrl, false); // third parameter of `false` means synchronous
    //             xhr.send(JSON.stringify(data));
    //         }
    //     }catch(e){
    //         // nothing here...
    //     }    
    // }
};